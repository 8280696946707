@use '../styles/theme' as theme;

.Production {
  padding-top: theme.$defaultPadding;

  .GasLiftLoadingIndicator {
    padding-top: theme.$defaultPadding;
  }

  .WellButtonGroupList {
    padding-top: 8px;
  }

  .TelemetryStatsList {
    padding-top: theme.$defaultPadding;
  }

  .ProductionForm {
    padding-top: theme.$defaultPadding;
    padding-bottom: theme.$defaultPadding;
  }

  .header {
    
    font-style: normal;
    font-size: 26px;
    line-height: 36px;
    width: 100%;
    
    @include theme.flo-font;

    @media (max-width: 768px) {
      font-size: 21px;
    }
  }

  .sub-header {
    font-weight: 200;
    font-size: 26px;
    line-height: 36px;
    margin-left: 6px;
    @include theme.flo-font;
    @media (max-width: 768px) {
      font-size: 21px;
    }
  }
}




