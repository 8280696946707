@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$white: #fff;
$light-grey: #e2e4e4;
$dark-grey: #666;
$background-grey: #8b8989;
$dark-background-grey: #3c3c3c;
$orange: #f05921;
$light-orange: #f09a2e;
$red: #f01628;
$light-red: #f8636f;
$blue: #076ea3;
$light-blue: #16a5f0;
$green: #27a344;
$light-green: #2ef05b;
$purple: #6f16f0;
$teal: #0af0c2;
$main-font: 'Roboto', sans-serif, Arial, Helvetica;
$small-border-radius: 5px;
$small-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$large-screen-width: 825px;
$transition: 0.25s all ease;

.center {
  text-align: center;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.flex {
  display: flex;
  align-items: center;
}

.gap {
  width: 100%;
  height: 20px;
}

.small-gap {
  width: 100%;
  height: 10px;
}

.margin-top {
  margin-top: 20px;
}

.margin-top-none {
  margin-top: 0;
}

.email-icon {
  width: 20px;
  height: 20px;
  background-image: url('../assets/images/email.svg');
}

.phone-icon {
  width: 20px;
  height: 20px;
  background-image: url('../assets/images/phone.svg');
}

.link {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  color: $light-blue;
  margin: auto auto auto 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ip-connected-logo {
  width: 24px;
  height: 24px;
  background-image: url('../assets/images/ip-connected.svg');
  margin: auto auto auto 10px;

  &[title]:hover::after {
    background-color: $dark-grey;
  }
}

.ip-disconnected-logo {
  width: 20.5px;
  height: 20.5px;
  background-image: url('../assets/images/ip-disconnected.svg');
  margin: auto auto auto 10px;

  &[title]:hover::after {
    background-color: $dark-grey;
  }
}

.ip-unknown-logo {
  width: 25px;
  height: 25px;
  background-image: url('../assets/images/ip-unknown.svg');
  margin: auto auto auto 10px;

  &[title]:hover::after {
    background-color: $dark-grey;
  }
}

/* Single Asset Header */

.single-asset-header-container {
  .single-asset-header {
    display: flex;
    height: 35px;
    color: $dark-grey;
    font-family: $main-font;

    .header {
      display: flex;
      align-items: center;
    }

    .sub-text {
      width: 100%;
      display: flex;
      height: 25px;
    }

    .sub-text {
      background-color: #076ea3;
    }
  }

  .mechanic-container {
    width: 100%;
    height: 25px;
    display: flex;
    font-size: 15px;
    font-family: $main-font;
    color: $dark-grey;
    margin: 0;

    p,
    b,
    .phone-icon {
      margin: auto 5px auto 0;
    }
  }

  .ip-container {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'Roboto';
    color: $dark-grey;

    p,
    b {
      margin: auto 0;
      margin-right: 5px;
    }

    .copy-address-icon {
      width: 16px;
      height: 16px;
      background-image: url('../assets/images/copy.svg');
      background-position: center;
      background-repeat: no-repeat;
      padding: 10px;
      border-radius: 50%;
      margin: 0 5px 0 10px;

      &:hover {
        cursor: pointer;
        background-color: $dark-grey;
      }

      &[title]:hover::after {
        background-color: $dark-grey;
      }
    }

    .edit-address-icon {
      width: 16px;
      height: 16px;
      background-image: url('../assets/images/edit.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 5px;
      padding: 10px;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        background-color: $dark-grey;
      }

      &[title]:hover::after {
        background-color: $dark-grey;
      }
    }

    .grey-info-icon {
      height: 15px;
      width: 15px;
      background-image: url('../assets/images/info-icon-grey.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 5px;

      &:hover {
        background-image: url('../assets/images/info-icon-darkgrey.svg');
      }

      &[title]:hover::after {
        background-color: $dark-grey;
      }
    }
  }

  .status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    font-family: $main-font;
    color: $dark-grey;

    .ip-modal-label,
    .ip-modal-value {
      margin: 0;
    }

    .ip-modal-value {
      margin: auto 10px auto 0;
    }

    .ip-connected-logo,
    .ip-disconnected-logo {
      margin: auto auto auto 0;
    }
  }
}

/* Alert modal */

.ip-alert-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 75px;
  display: flex;
  justify-content: center;
}

.ip-alert-container {
  min-width: 300px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $main-font;
  color: $dark-grey;
  background-color: $white;
  border-radius: $small-border-radius;
  box-shadow: $small-box-shadow;
  padding: 10px;

  p {
    color: $orange;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: auto 0 auto 10px;
  }

  .ip-alert-icon {
    margin: auto 0;
    width: 24px;
    height: 24px;
    background-image: url('../assets/images/info-icon.svg');
  }
}

/* IP Edit Modal */

.ip-edit-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  place-items: center;
  overflow-y: scroll;
}

.ip-edit-modal-container {
  width: 350px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  font-family: $main-font;
  color: $dark-grey;
  background-color: $white;
  border-radius: $small-border-radius;
  padding-bottom: 10px;

  .ip-edit-modal-header {
    position: absolute;
    z-index: 1000;
    height: 50px;
    width: 350px;
    display: flex;
    background-color: $orange;
    margin: 0 0 auto 0;
    border-bottom: solid 2px $white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .ip-edit-modal-header-text {
      font-size: 20px;
      color: $white;
      letter-spacing: 0.5px;
      margin: auto;
      padding: 10px 0;
    }

    .ip-edit-modal-close {
      position: absolute;
      z-index: 1000;
      height: 50px;
      width: 350px;
      color: $white;
      font-size: 16px;
      margin-top: 12.5px;
      margin-left: 94%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  // push rows down below header
  :nth-child(2) {
    margin-top: 60px;
  }

  .ip-edit-modal-row {
    width: 300px;
    height: 30px;
    display: flex;
    align-items: center;

    .ip-edit-modal-label {
      width: 125px;
    }

    .ip-edit-modal-label,
    .ip-edit-modal-value {
      font-size: 18px;
      font-weight: 500;
      margin: auto 5px auto 0;
      color: $dark-grey;
    }
  }

  .ip-edit-modal-line {
    width: 85%;
    height: 3px;
    background-color: $dark-grey;
  }

  .ip-edit-modal-text-input-row {
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    .ip-edit-modal-input-label {
      font-size: 20px;
      font-weight: 500;
      color: $dark-grey;
      margin: 0 auto;
    }

    .ip-edit-modal-text-input,
    .ip-edit-modal-select-input {
      width: 300px;
      height: 40px;
      border: solid 3px $light-grey;
      border-radius: 5px;
      margin: 0;
      text-align: center;
    }

    .ip-edit-modal-text-input:focus,
    .ip-edit-modal-select-input:focus {
      outline: none;
      border: 3px solid $orange;
      border-radius: 5px;
    }
  }

  .ip-error-message {
    width: 85%;
    font-size: 12px;
    color: $red;
    text-align: right;
    margin-top: 0;
  }

  .ip-error-message-placeholder {
    height: 20px;
    width: 85%;
  }

  .ip-loading-animation-container {
    height: 222px;
    width: 85%;
    display: grid;
    place-items: center;

    .ip-loading-animation {
      height: 50px;
      width: 300px;
      background-image: url('../assets/images/ip-edit-loading.gif');
    }
  }

  .api-error-message {
    height: 60px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $red;
    font-size: 16px;
    font-weight: bold;

    .link {
      margin: auto;
    }
  }

  .api-error-message-placeholder {
    height: 60px;
    width: 100%;
  }

  .buttons-container {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      width: 100px;
      height: 40px;
      font-weight: 500;
      color: $dark-grey;
      border: none;
      margin: 10px 0 10px 10px;
      border-radius: 5px;

      &:disabled {
        color: $dark-grey;
        background-color: $light-grey;
        border: $light-grey;

        &:hover {
          cursor: default;
          color: $dark-grey;
          background-color: $light-grey;
          border: $light-grey;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .large-button {
      width: 200px;
    }

    .primary-button {
      color: $white;
      background-color: $orange;
      border: solid 2px $orange;

      &:hover {
        cursor: pointer;
        background-color: $light-orange;
        border: solid 2px $light-orange;
      }
    }

    .secondary-button {
      color: $orange;
      background-color: $white;
      border: solid 2px $orange;

      &:hover {
        color: $white;
        background-color: $orange;
      }
    }
  }

  .ip-error-contact-row {
    height: 30px;
    width: 300px;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;

    .ip-error-contact-row-label {
      width: 150px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin: auto 0;
    }

    .ip-error-contact-info {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto 0;

      .ip-error-contact-row-email {
        display: flex;
        align-items: center;
        margin: auto 0;

        .ip-contact-email {
          width: 20px;
          height: 20px;
          background-image: url('');
          margin-right: 5px;
        }

        a {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.25px;
          margin: 0 10px 0 0;
        }
      }

      .ip-error-contact-row-phone {
        display: flex;
        align-items: center;
        margin: auto 0;

        .ip-contact-phone {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.25px;
          margin: 0;
        }
      }
    }
  }

  .contact-support-placeholder {
    height: 30px;
    width: 85%;
    background-color: $white;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Larger screens like desktops */
@media (min-width: 768px) {
  .ip-edit-modal-container {
    width: 700px;
    transition: $transition;

    .ip-edit-modal-header {
      height: 50px;
      width: 700px;
      transition: $transition;

      .ip-edit-modal-close {
        margin-left: 97%;
        transition: $transition;
      }
    }

    .ip-edit-modal-row {
      width: 600px;
      transition: $transition;
    }

    .ip-edit-modal-text-input-row {
      width: 85%;
      flex-direction: row;
      justify-content: space-between;
      transition: $transition;

      .ip-edit-modal-input-label {
        margin: auto 0;
        transition: $transition;
      }

      .ip-edit-modal-text-input,
      .ip-edit-modal-select-input {
        width: 425px;
        text-align: left;
        transition: $transition;
      }
    }

    .ip-error-message {
      transform: translateY(-12px);
      transition: $transition;
    }

    .buttons-container {
      width: 85%;
      justify-content: right;
      transition: $transition;
    }

    .ip-error-contact-row {
      width: 600px;
      display: flex;
      flex-direction: row;
      transition: $transition;

      .ip-error-contact-row-label {
        width: 115px;
        margin-right: 10px;
      }

      .ip-error-contact-info {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .contact-support-placeholder {
      height: 35px;
      width: 85%;
    }
  }
}
