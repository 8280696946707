.GasLiftLoadingIndicator {
  text-align: center;
  padding-top: 6em;
  display: grid;
  &--loadingMessage {
    color: #00000073;
  }
  > div > div {
    background-color: #FF6600;
  }
}
