@use '../styles/theme' as theme;


.well-information-edit {
  min-height: 300px;

  .edit-button {
    color: theme.$flo-medium-grey;
    padding-left: .5em;
  }

  // antd overrides
  .ant-form-item-label {
    text-align: left;
    overflow: visible;
  }

  .ant-form-item {
    justify-content: space-around;
    margin-bottom: 8px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  textarea {
    height: 150px;
  }

  @include theme.flo-text-inputs;

  .header {
    display: flex;
    > div {
      margin-left: 28px;
      border-bottom: 1px solid theme.$flo-medium-grey;
      width: 100%;
      margin-right: 30px;
      padding-bottom: 8px;
      margin-bottom: 16px;
    }
  }
}
