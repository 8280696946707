@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$white: #fff;
$light-grey: #e2e4e4;
$dark-grey: #666;
$orange: #f05921;
$red: #f01628;
$blue: #076ea3;
$green: #27a344;
$light-green: #2ef05b;
$purple: #6f16f0;
$main-font: 'Roboto', sans-serif, Arial, Helvetica;
$small-border-radius: 5px;
$small-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

.gap {
  height: 20px;
  width: 100%;
}

.custom-tooltip-container {
  height: 50px;
  display: flex;
  justify-content: center;
}

.custom-tooltip {
  min-width: 500px;
  max-width: 1050px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  font-family: $main-font;
  background-color: $white;
  padding: 0 10px;
  border: solid $light-grey 2px;
  border-radius: $small-border-radius;
  box-shadow: $small-box-shadow;

  .label {
    color: $dark-grey;
    font-size: 14px;
    font-weight: bold;
    margin: auto 10px;
  }

  .value0,
  .value1,
  .value2,
  .value3 {
    font-size: 14px;
    margin: auto 5px;
  }
}
