@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Variables */
$blue: #076ea3;
$dark-grey: #666;
$light-grey: #e2e4e4;
$orange: #f05921;
$white: #fff;
$main-font: 'Roboto', sans-serif, Arial, Helvetica;
$small-border-radius: 5px;

.large-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $main-font;
  background-color: $white;
  padding-bottom: 100px;

  .report-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-grey;
    background-color: $white;
    margin-top: 20px;
  }
}

.report-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
}

.form-row {
  width: 350px;
  margin: 0 auto;
}

.organization-row {
  height: 75px;
  width: 350px;
  margin: 0 auto;
}

.report-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.top-logo {
  width: 165px;
  height: 82.5px;
  background-image: url('../../assets/images/updatedLogo.png');
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  margin: 0 auto;
}

.displayed-options-title {
  width: 100%;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
}

.gap {
  width: 100%;
  height: 20px;
}

.selected {
  .card-line {
    background-color: $white;
  }
}

.line {
  width: 85%;
  height: 1px;
  background-color: #222;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.border-none {
  border: none;
}

input:focus {
  outline: none;
}

.orgId-container:focus,
.schedule-time-container:focus,
.schedule-day-container:focus,
.area-container:focus,
.frequency-container:focus,
.schedule-day-container:focus-within,
.report-name-container:focus {
  outline: none;
  border: 3px solid $orange;
  border-radius: 5px;
}

.report-search-container:focus-within,
.report-name-container:focus-within {
  outline: none;
  border: 3px solid $orange;
  border-radius: 5px;
}

.date-input:focus {
  outline: 3px solid $orange;
  border-radius: 2px;
}

/* Organization */

.organization-label {
  font-weight: 600;
  font-size: 32px;
  color: $orange;
  height: 40px;
}

.orgId-container {
  height: 50px;
  width: 350px;
  display: flex;
  font-weight: 600;
  margin-right: auto;
  border: solid 3px $light-grey;
  border-radius: $small-border-radius;
}

/* Area */

.area-container {
  height: 50px;
  width: 350px;
  display: flex;
  font-weight: 600;
  border: solid 3px $light-grey;
  border-radius: $small-border-radius;
  padding: 10px 5px;
  margin-right: auto;
}

/* Date Range */

.date-range-container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  border: solid 3px $light-grey;
  border-radius: $small-border-radius;
  padding: 10px 5px;
  margin: auto;

  .date-input {
    width: 50%;
    border: none;
  }

  .date-range-dash {
    height: 2px;
    width: 10px;
    background-color: #333;
    margin: auto 5px;
  }
}

/* Search */

.report-search-container {
  height: 50px;
  width: 350px;
  display: flex;
  align-items: center;
  border: solid 2px $light-grey;
  padding: 0 5px;
  border: solid 3px $light-grey;
  border-radius: $small-border-radius;

  .search-svg {
    height: 24px;
    width: 24px;
    background-image: url('../../assets/images/search.svg');
  }

  .report-search {
    width: 100%;
    padding: 5px;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

/* Report Cards */

.report-cards {
  max-height: 425px;
  width: 350px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: scroll;
  margin: 0 auto;

  .report-card {
    height: 200px;
    width: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $dark-grey;
    background-color: $light-grey;
    border-radius: $small-border-radius;
    padding-top: 20px;
    margin: 0 auto 10px 0;
    text-align: center;

    .report-card-title {
      height: 25%;
      width: 85%;
      display: grid;
      place-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
    }

    .report-card-description {
      height: 50%;
      width: 125px;
      font-size: 12px;
      font-weight: 600;
    }

    .card-line {
      width: 85%;
      height: 3px;
      background-color: $dark-grey;
      margin-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: #ff713d;

      .report-card-title {
        color: $white;
      }

      .report-card-description {
        color: $white;
      }

      .card-line {
        background-color: $white;
      }
    }

    &:last-child {
      justify-self: start;
    }
  }

  .selected {
    background-color: $orange;
    color: $white;

    &:hover {
      background-color: $orange;
    }

    .card-line {
      background-color: $white;
    }
  }
}

/* Buttons */

.toggle-forms-button {
  font-size: 14px;
  font-weight: bold;
}

.button-container {
  width: 100%;
  display: grid;
  place-items: center;
}

.buttons-container {
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.report-submit-button {
  min-height: 50px;
  width: 100px;
  font-weight: 600;
  border: solid 3px $orange;
  border-radius: $small-border-radius;
  margin: 10px auto;
  color: $dark-grey;
  background-color: $white;

  &:hover {
    cursor: pointer;
    background-color: #ff713d;
    border: solid 3px #ff713d;
    color: $white;
  }
}

.report-schedule-button {
  min-height: 50px;
  width: 168px;
  font-weight: 600;
  border: solid 3px $blue;
  border-radius: $small-border-radius;
  margin: 10px auto;
  color: $dark-grey;
  background-color: $white;

  &:hover {
    cursor: pointer;
    color: $white;
    border: solid 3px $blue;
    background-color: $blue;
  }
}

.error-button {
  min-height: 50px;
  width: 100px;
  font-weight: 600;
  border: solid 3px $white;
  color: $white;
  border-radius: $small-border-radius;
  margin: 10px auto;
  background-color: $orange;

  &:hover {
    cursor: pointer;
    background-color: $white;
    color: $orange;
  }
}

/* Options Modal */

.options-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  place-items: center;
  overflow-y: scroll;
}

.options-modal {
  width: 400px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $small-border-radius;
  overflow: hidden;
}

.options-header {
  position: absolute;
  z-index: 1000;
  height: 50px;
  width: 350px;
  display: flex;
  background-color: $orange;
  margin-bottom: auto;
  border-bottom: solid 2px $white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .options-header-text {
    font-size: 20px;
    color: $white;
    margin: auto;
    padding: 10px 0;
  }

  .options-close {
    position: absolute;
    z-index: 1000;
    height: 50px;
    width: 350px;
    font-weight: bold;
    color: $white;
    margin-left: 95%;

    &:hover {
      cursor: pointer;
    }
  }
}

.options-container {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  border-radius: $small-border-radius;
  background-color: $white;

  .displayed-checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .additional-boolean-option {
    width: 200px;
    height: 50px;
    display: grid;
    place-items: center;
    background-color: $orange;
    border-radius: $small-border-radius;
    margin: 5px;

    &:hover {
      cursor: pointer;
      background-color: #ff713d;
    }
  }

  .additional-boolean-option-deselected {
    width: 200px;
    height: 50px;
    display: grid;
    place-items: center;
    background-color: $light-grey;
    border-radius: $small-border-radius;
    margin: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .option-title {
    color: $white;
    margin: 0 auto;
    font-size: 16px;
  }

  .additional-option-select {
    margin: 0 auto;
    margin-top: 75px;
    width: 300px;
    height: 40px;
  }

  .select-option {
    font-weight: bold;
    color: #222;
  }

  .options-details-info {
    text-align: center;
    width: 90%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 75px;
  }
}

/* Scheduling Section */

.schedule-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  margin-top: 30px;
  border-radius: $small-border-radius;
  background-color: $white;
}

.report-name-container {
  height: 50px;
  width: 350px;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  border: solid 3px $light-grey;
  border-radius: $small-border-radius;
  color: $dark-grey;
}

.report-name-input {
  width: 100%;
  border: none;
  margin-left: 2px;
}

.frequency-container {
  height: 50px;
  width: 100%;
  padding: 10px 5px;
  border-radius: $small-border-radius;
  border: solid 3px $light-grey;

  .frequency {
    width: 145px;
    background-color: $light-grey;
    border-radius: 25px;
    border: none;
    margin-left: 10px;
  }
}

.schedule-day-container {
  height: 50px;
  width: 100%;
  padding: 10px 5px;
  border-radius: $small-border-radius;
  border: solid 3px $light-grey;

  .month-day {
    width: 100%;
    border: none;

    &:focus {
      border: none;
    }
  }
}

.schedule-time-container {
  height: 50px;
  width: 100%;
  padding: 10px 5px;
  border-radius: $small-border-radius;
  border: solid 3px $light-grey;
}

/* Alert modal */

.alert-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 75px;
  display: grid;
  place-items: center;
  overflow-y: scroll;
}

.alert-modal {
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $orange;
  border-radius: $small-border-radius;
}

.alert-message {
  width: 85%;
  color: $white;
  font-size: 16px;
  text-align: center;
  margin: auto;
}

.alert-close {
  font-weight: bold;
  color: $white;
  font-size: 22px;
  margin: auto;

  &:hover {
    cursor: pointer;
  }
}

/* Error modal */

.error-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 75px;
  display: grid;
  place-items: center;
  overflow-y: scroll;
}

.error-modal {
  width: 500px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  background-color: $orange;
  border-radius: $small-border-radius;
}

.error-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .error-icon {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/images/error-icon.svg');
    margin: 20px 0;
  }

  .error-message {
    color: $white;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

/* Scheduled Reports */

.scheduled-reports-container {
  min-height: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 3px $orange;
  border-radius: 10px;
  border-top: none;
  margin: 0 auto;
  margin-top: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.scheduled-reports-header {
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $orange;
  color: $white;
  margin: 0 0 10px 0;
  border: solid 3px $orange;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.scheduled-reports-header-text {
  color: $white;
  margin: auto;
}

.scheduled-reports-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.displayed-report {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: solid 2px $dark-grey;
}

.displayed-report-name {
  font-size: 14px;
  font-weight: bold;
  color: $dark-grey;
  margin: auto 0;
}

.displayed-report-icons-container {
  display: flex;
  align-items: center;
}

.displayed-report-icon {
  height: 24px;
  width: 24px;
}

.displayed-report-icon:hover {
  cursor: pointer;
}

.displayed-report-info-icon {
  background-image: url('../../assets/images/info-icon.svg');
  margin-right: 15px;
}

.displayed-report-delete-icon {
  background-image: url('../../assets/images/trash-icon.svg');
}

/* Scheduled Report Modal */

.scheduled-report-modal-overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  place-items: center;
}

.scheduled-report-modal {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  margin-top: 50px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.scheduled-report-header {
  position: absolute;
  z-index: 1000;
  height: 50px;
  width: 350px;
  display: flex;
  background-color: $orange;
  border-bottom: solid 2px $white;
  border-top-left-radius: $small-border-radius;
  border-top-right-radius: $small-border-radius;
}

.scheduled-report-header-text {
  width: 300px;
  font-size: 20px;
  color: $white;
  text-align: center;
  margin: auto;
}

.scheduled-report-close {
  position: absolute;
  font-weight: bold;
  color: $white;
  margin-top: 12.5px;

  &:hover {
    cursor: pointer;
  }
}

.scheduled-report-title {
  color: $dark-grey;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 60px;
}

.scheduled-report-row {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0 25px;
  border-bottom: 3px solid $dark-grey;
}

.scheduled-report-row-title {
  font-size: 18px;
  font-weight: bold;
  color: $orange;
  margin: 0;
  margin-right: auto;
}

.scheduled-report-row-content {
  font-size: 18px;
  font-weight: bold;
  color: $dark-grey;
  margin-right: auto;
  margin-bottom: 5px;
}

/* Scrollbars and Modal Stuff*/

.report-cards::-webkit-scrollbar {
  width: 5px;
}
.report-cards::-webkit-scrollbar-track {
  background: #d9d9d9;
}
.report-cards::-webkit-scrollbar-thumb {
  background: $orange;
}

.options-modal::-webkit-scrollbar {
  width: 5px;
}
.options-modal::-webkit-scrollbar-track {
  background: $white;
}
.options-modal::-webkit-scrollbar-thumb {
  background: $orange;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Larger screens like desktops */
@media (min-width: 825px) {
  .report-section {
    width: 750px;
    transition: all 0.25s ease;
  }

  .form-row {
    width: 750px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s ease;
  }

  .organization-row {
    width: 750px;
    transition: all 0.25s ease;
  }

  .buttons-container {
    width: 275px;
    transition: all 0.25s ease;
  }

  .orgId-container {
    width: 750px;
    transition: all 0.25s ease;
  }

  .organization-label {
    width: 750px;
    transition: all 0.25s ease;
  }

  .report-search-container {
    width: 750px;
    transition: all 0.25s ease;
  }

  .report-cards {
    width: 750px;
    transition: all 0.25s ease;
  }

  .options-modal {
    max-height: 375px;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    overflow: hidden;
    transition: all 0.25s ease;
  }

  .options-container {
    width: 700px;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    transition: all 0.25s ease;
  }

  .options-header {
    width: 700px;
    transition: all 0.25s ease;

    .options-close {
      margin-left: 97%;
      margin-top: 12.5px;
    }
  }

  .displayed-checkboxes {
    width: 700px;
    height: 125px;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.25s ease;
  }

  .additional-boolean-option,
  .additional-boolean-option-deselected {
    &:last-child {
      margin-bottom: auto;
    }
  }

  .schedule-section {
    width: 950px;
    transition: all 0.25s ease;
  }

  .schedule-container {
    width: 950px;
    transition: all 0.25s ease;
  }

  .schedule-header {
    width: 950px;
    transition: all 0.25s ease;
  }

  .report-name-container {
    width: 750px;
    transition: all 0.25s ease;
  }

  .scheduled-reports-container {
    width: 750px;
    transition: 0.25s ease all;
  }

  .scheduled-reports-header {
    width: 750px;
    transition: 0.25s ease all;
  }

  .displayed-report {
    width: 700px;
    transition: 0.25s ease all;
  }

  .scheduled-report-modal {
    width: 750px;
    transition: 0.25s ease all;
  }

  .scheduled-report-header {
    width: 750px;
    transition: 0.25s ease all;

    .scheduled-report-close {
      margin-left: 97%;
    }
  }

  .scheduled-report-row {
    width: 700px;
    transition: 0.25s ease all;
  }
}
