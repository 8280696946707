.TelemetryStat {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 'none';
  height: 100%;
  background: white;
  box-shadow: 1px 1px 2px #cdd4da;
  border: 1px solid #cdd4da;

  // ANT default overrides 
  .ant-statistic-content {
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: #666666;
    width: 100%;
    padding: 0;

    .ant-statistic-content-value {
      overflow-wrap: break-word;
      word-break: break-word;
    }

    ::-webkit-scrollbar {
      display: none
    }
  }

  .ant-card-body {
    padding: 0;
    margin: auto;
    width: 50%;
  }

  .ant-statistic {
    width: 100%;
  }

  .ant-statistic-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000073;
    width: 100%;
    padding: 0;
  }
}
