// this will be iterated on to eventually create a global theme...starting local for now.  aim small...miss small.
$flo-orange: #F05921;
$activeBGColor: white;
$buttonRadius: 5px;
$flo-medium-grey: #666;
$defaultPadding: 24px;

@mixin flo-font {
  color: $flo-medium-grey;
  font-family: Roboto;
}

@mixin page_padding {
  padding: $defaultPadding $defaultPadding 0 $defaultPadding;
}

@mixin flo-text-inputs {
  input:focus,
  textarea:focus,
  input:hover,
  textarea:hover {
    border: 1px $flo-orange;
    box-shadow: 0 0 0 1px $flo-orange;
  }

  input:focus,
  textarea:focus {
    box-shadow: 0 0 0 2px $flo-orange;
  }

  input,
  textarea {
    border-radius: 3px;
  }

  label {
    color: #666;
    font-family: 'Roboto';
  }
}

@mixin flo-button {
  .flo-button {
    background-color: $activeBGColor;
    font-family: 'Roboto';
    font-weight: 400;
  }

  .ant-btn {
    border: 2px solid $flo-orange;
    color: $flo-medium-grey;
    font-size: 12px;  
    display: flex;
    align-items: center;
    justify-content: center;
    &[disabled] {
      opacity: .25
    }
  }

  .ant-btn:hover, .ant-btn:focus {
    color: $flo-orange;
  }
}

