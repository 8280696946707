@use '../styles/theme' as theme;

.gas-lift-modal {

  .ant-modal-header {
    background-color: theme.$flo-orange;
    border-top-left-radius: theme.$buttonRadius;
    border-top-right-radius: theme.$buttonRadius;
  }

  .ant-modal-content {
    border-radius: theme.$buttonRadius;
  }

  .ant-modal-close:focus,
  .ant-modal-close:hover,
  .ant-modal-close {
    color: theme.$activeBGColor;
  }

  .ant-modal-close-x {
    line-height: 34px;
    width: 34px;
  }

  @include theme.flo-button;
}

