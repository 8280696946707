@use '../styles/theme' as theme;

.well-information-edit-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  justify-content: flex-end;

  @include theme.flo-button;

  .flo-button {
    width: 64px;
    border-radius: 5px;
    height: 32px;
    margin-left: 32px;
    margin-top: 16px;
  }
}
