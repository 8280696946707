 @use '../styles/theme' as theme;

$wellButtonHeight:  40px;
$wellConfigurationButtonWidth: 42px;
$wellSelectionButtonWidth: 144px;
$needsConfigurationColor: #edeff3;
$disabledOrange: #F0592180;

.WellButtonGroup {

  // antd overrides
  .ant-btn {
    border-radius: 0;
  }

  @include theme.flo-button;

  .selectionButton,
  .configurationButton {
    font-size: 16px;
    height: $wellButtonHeight;
  }

  .selectionButton {
    width: $wellSelectionButtonWidth;
    border-top-left-radius: theme.$buttonRadius;
    border-bottom-left-radius:theme.$buttonRadius;
    border-right: none;
  }

  .configurationButton {
    width: $wellConfigurationButtonWidth;
    border-top-right-radius: theme.$buttonRadius;
    border-bottom-right-radius: theme.$buttonRadius;
    
    svg {
      color: theme.$flo-medium-grey;
    }

    &:hover {
      svg {
        color: theme.$flo-orange;
      }
    }
  }

  &.needs-configuration {
    .selectionButton {
      background-color: $needsConfigurationColor;
      border: 2px solid $disabledOrange;
      border-right: none;
      
      &:focus,
      &:hover {
        color: theme.$flo-medium-grey;
        cursor: not-allowed;
      }
    }
  }

  &.selected {
    .selectionButton {
      background-color: theme.$flo-orange;
      color: theme.$activeBGColor;
    }
  }
}
